// export const countries = [
//   { ddi: "+93", pais: "Afeganistão", continente: "Ásia" },
//   { ddi: "+27", pais: "África do Sul", continente: "África" },
//   { ddi: "+355", pais: "Albânia", continente: "Europa" },
//   { ddi: "+49", pais: "Alemanha", continente: "Europa" },
//   { ddi: "+376", pais: "Andorra", continente: "Europa" },
//   { ddi: "+244", pais: "Angola", continente: "África" },
//   { ddi: "+1", pais: "Anguilla", continente: "América Central" },
//   { ddi: "+1", pais: "Antígua e Barbuda", continente: "América Central" },
//   { ddi: "+599", pais: "Antilhas Holandesas", continente: "América Central" },
//   { ddi: "+966", pais: "Arábia Saudita", continente: "Ásia" },
//   { ddi: "+213", pais: "Argélia", continente: "África" },
//   { ddi: "+54", pais: "Argentina", continente: "América do Sul" },
//   { ddi: "+374", pais: "Armênia", continente: "Ásia" },
//   { ddi: "+297", pais: "Aruba", continente: "América Central" },
//   { ddi: "+247", pais: "Ascensão", continente: "África" },
//   { ddi: "+61", pais: "Austrália", continente: "Oceania" },
//   { ddi: "+43", pais: "Áustria", continente: "Europa" },
//   { ddi: "+994", pais: "Azerbaijão", continente: "Ásia" },
//   { ddi: "+1", pais: "Bahamas", continente: "América Central" },
//   { ddi: "+880", pais: "Bangladesh", continente: "Ásia" },
//   { ddi: "+1", pais: "Barbados", continente: "América Central" },
//   { ddi: "+973", pais: "Bahrein", continente: "Ásia" },
//   { ddi: "+32", pais: "Bélgica", continente: "Europa" },
//   { ddi: "+501", pais: "Belize", continente: "América Central" },
//   { ddi: "+229", pais: "Benim", continente: "África" },
//   { ddi: "+1", pais: "Bermudas", continente: "América Central" },
//   { ddi: "+375", pais: "Bielorrússia", continente: "Europa" },
//   { ddi: "+591", pais: "Bolívia", continente: "América do Sul" },
//   { ddi: "+387", pais: "Bósnia e Herzegovina", continente: "Europa" },
//   { ddi: "+267", pais: "Botswana", continente: "África" },
//   { ddi: "+55", pais: "Brasil", continente: "América do Sul" },
//   { ddi: "+673", pais: "Brunei", continente: "Ásia" },
//   { ddi: "+359", pais: "Bulgária", continente: "Europa" },
//   { ddi: "+226", pais: "Burkina Faso", continente: "África" },
//   { ddi: "+257", pais: "Burundi", continente: "África" },
//   { ddi: "+975", pais: "Butão", continente: "Ásia" },
//   { ddi: "+238", pais: "Cabo Verde", continente: "África" },
//   { ddi: "+237", pais: "Camarões", continente: "África" },
//   { ddi: "+855", pais: "Camboja", continente: "Ásia" },
//   { ddi: "+1", pais: "Canadá", continente: "América do Norte" },
//   { ddi: "+7", pais: "Cazaquistão", continente: "Ásia" },
//   { ddi: "+237", pais: "Chade", continente: "África" },
//   { ddi: "+56", pais: "Chile", continente: "América do Sul" },
//   { ddi: "+86", pais: "República Popular da China", continente: "Ásia" },
//   { ddi: "+357", pais: "Chipre", continente: "Europa" },
//   { ddi: "+57", pais: "Colômbia", continente: "América do Sul" },
//   { ddi: "+269", pais: "Comores", continente: "África" },
//   { ddi: "+242", pais: "Congo-Brazzaville", continente: "África" },
//   { ddi: "+243", pais: "Congo-Kinshasa", continente: "África" },
//   { ddi: "+850", pais: "Coreia do Norte", continente: "Ásia" },
//   { ddi: "+82", pais: "Coreia do Sul", continente: "Ásia" },
//   { ddi: "+225", pais: "Costa do Marfim", continente: "África" },
//   { ddi: "+506", pais: "Costa Rica", continente: "América Central" },
//   { ddi: "+385", pais: "Croácia", continente: "Europa" },
//   { ddi: "+53", pais: "Cuba", continente: "América Central" },
//   { ddi: "+45", pais: "Dinamarca", continente: "Europa" },
//   { ddi: "+253", pais: "Djibuti", continente: "África" },
//   { ddi: "+1", pais: "Dominica", continente: "América Central" },
//   { ddi: "+20", pais: "Egipto", continente: "África/Ásia" },
//   { ddi: "+503", pais: "El Salvador", continente: "América Central" },
//   { ddi: "+971", pais: "Emirados Árabes Unidos", continente: "Ásia" },
//   { ddi: "+593", pais: "Equador", continente: "América do Sul" },
//   { ddi: "+291", pais: "Eritreia", continente: "África" },
//   { ddi: "+421", pais: "Eslováquia", continente: "Europa" },
//   { ddi: "+386", pais: "Eslovénia", continente: "Europa" },
//   { ddi: "+34", pais: "Espanha", continente: "Europa" },
//   { ddi: "+1", pais: "Estados Unidos", continente: "América do Norte" },
//   { ddi: "+372", pais: "Estónia", continente: "Europa" },
//   { ddi: "+251", pais: "Etiópia", continente: "África" },
//   { ddi: "+679", pais: "Fiji", continente: "Oceania" },
//   { ddi: "+63", pais: "Filipinas", continente: "Ásia" },
//   { ddi: "+358", pais: "Finlândia", continente: "Europa" },
//   { ddi: "+33", pais: "França", continente: "Europa" },
//   { ddi: "+241", pais: "Gabão", continente: "África" },
//   { ddi: "+220", pais: "Gâmbia", continente: "África" },
//   { ddi: "+233", pais: "Gana", continente: "África" },
//   { ddi: "+995", pais: "Geórgia", continente: "Ásia" },
//   { ddi: "+350", pais: "Gibraltar", continente: "Europa" },
//   { ddi: "+1", pais: "Granada", continente: "América Central" },
//   { ddi: "+30", pais: "Grécia", continente: "Europa" },
//   { ddi: "+299", pais: "Groenlândia", continente: "América do Norte" },
//   { ddi: "+590", pais: "Guadalupe", continente: "América Central" },
//   { ddi: "+671", pais: "Guam", continente: "Oceania" },
//   { ddi: "+502", pais: "Guatemala", continente: "América Central" },
//   { ddi: "+592", pais: "Guiana", continente: "América do Sul" },
//   { ddi: "+594", pais: "Guiana Francesa", continente: "América do Sul" },
//   { ddi: "+224", pais: "Guiné", continente: "África" },
//   { ddi: "+245", pais: "Guiné-Bissau", continente: "África" },
//   { ddi: "+240", pais: "Guiné Equatorial", continente: "África" },
//   { ddi: "+509", pais: "Haiti", continente: "América Central" },
//   { ddi: "+504", pais: "Honduras", continente: "América Central" },
//   {
//     ddi: "+852",
//     pais: "Hong Kong",
//     continente: "Ásia",
//   },
//   {
//     ddi: "+36",
//     pais: "Hungria",
//     continente: "Europa",
//   },
//   {
//     ddi: "+967",
//     pais: "Iêmen",
//     continente: "Ásia",
//   },
//   {
//     ddi: "+1",
//     pais: "Ilhas Cayman",
//     continente: "América Central",
//   },
//   {
//     ddi: "+672",
//     pais: "Ilha Christmas",
//     continente: "Oceania",
//   },
//   {
//     ddi: "+672",
//     pais: "Ilhas Cocos",
//     continente: "Oceania",
//   },
//   {
//     ddi: "+682",
//     pais: "Ilhas Cook",
//     continente: "Oceania",
//   },
//   {
//     ddi: "+298",
//     pais: "Ilhas Féroe",
//     continente: "Europa",
//   },
//   {
//     ddi: "+672",
//     pais: "Ilha Heard e Ilhas McDonald",
//     continente: "Oceania",
//   },
//   {
//     ddi: "+960",
//     pais: "Maldivas",
//     continente: "Ásia",
//   },
//   {
//     ddi: "+500",
//     pais: "Ilhas Malvinas",
//     continente: "América do Sul",
//   },
//   {
//     ddi: "+1",
//     pais: "Ilhas Marianas do Norte",
//     continente: "Oceania",
//   },
//   {
//     ddi: "+692",
//     pais: "Ilhas Marshall",
//     continente: "Oceania",
//   },
//   {
//     ddi: "+672",
//     pais: "Ilha Norfolk",
//     continente: "Oceania",
//   },
//   {
//     ddi: "+677",
//     pais: "Ilhas Salomão",
//     continente: "Oceania",
//   },
//   {
//     ddi: "+1",
//     pais: "Ilhas Virgens Americanas",
//     continente: "América Central",
//   },
//   {
//     ddi: "+1",
//     pais: "Ilhas Virgens Britânicas",
//     continente: "América Central",
//   },
//   {
//     ddi: "+91",
//     pais: "Índia",
//     continente: "Ásia",
//   },
//   {
//     ddi: "+62",
//     pais: "Indonésia",
//     continente: "Ásia/Oceania",
//   },
//   {
//     ddi: "+98",
//     pais: "Irã",
//     continente: "Ásia",
//   },
//   {
//     ddi: "+964",
//     pais: "Iraque",
//     continente: "Ásia",
//   },
//   {
//     ddi: "+353",
//     pais: "Irlanda",
//     continente: "Europa",
//   },
//   {
//     ddi: "+354",
//     pais: "Islândia",
//     continente: "Europa",
//   },
//   { ddi: "+972", pais: "Israel", continente: "Ásia" },
//   { ddi: "+39", pais: "Itália", continente: "Europa" },
//   { ddi: "+1", pais: "Jamaica", continente: "América Central" },
//   { ddi: "+81", pais: "Japão", continente: "Ásia" },
//   { ddi: "+962", pais: "Jordânia", continente: "Ásia" },
//   { ddi: "+686", pais: "Kiribati", continente: "Oceania" },
//   { ddi: "+383", pais: "Kosovo", continente: "Europa" },
//   { ddi: "+965", pais: "Kuwait", continente: "Ásia" },
//   { ddi: "+856", pais: "Laos", continente: "Ásia" },
//   { ddi: "+266", pais: "Lesoto", continente: "África" },
//   { ddi: "+371", pais: "Letônia", continente: "Europa" },
//   { ddi: "+961", pais: "Líbano", continente: "Ásia" },
//   { ddi: "+231", pais: "Libéria", continente: "África" },
//   { ddi: "+218", pais: "Líbia", continente: "África" },
//   { ddi: "+423", pais: "Liechtenstein", continente: "Europa" },
//   { ddi: "+370", pais: "Lituânia", continente: "Europa" },
//   { ddi: "+352", pais: "Luxemburgo", continente: "Europa" },
//   { ddi: "+853", pais: "Macau", continente: "Ásia" },
//   { ddi: "+389", pais: "República da Macedônia", continente: "Europa" },
//   { ddi: "+261", pais: "Madagascar", continente: "África" },
//   { ddi: "+60", pais: "Malásia", continente: "Ásia" },
//   { ddi: "+265", pais: "Malawi", continente: "África" },
//   { ddi: "+223", pais: "Mali", continente: "África" },
// ];

export const countries = [
  {
    ddi: "93",
    pais: "Afeganistão +93",
    continente: "Ásia",
  },
  {
    ddi: "27",
    pais: "África do Sul +27",
    continente: "África",
  },
  {
    ddi: "355",
    pais: "Albânia +355",
    continente: "Europa",
  },
  {
    ddi: "49",
    pais: "Alemanha +49",
    continente: "Europa",
  },
  {
    ddi: "376",
    pais: "Andorra +376",
    continente: "Europa",
  },
  {
    ddi: "244",
    pais: "Angola +244",
    continente: "África",
  },
  {
    ddi: "1",
    pais: "Anguilla +1",
    continente: "América Central",
  },
  {
    ddi: "1",
    pais: "Antígua e Barbuda +1",
    continente: "América Central",
  },
  {
    ddi: "599",
    pais: "Antilhas Holandesas +599",
    continente: "América Central",
  },
  {
    ddi: "966",
    pais: "Arábia Saudita +966",
    continente: "Ásia",
  },
  {
    ddi: "213",
    pais: "Argélia +213",
    continente: "África",
  },
  {
    ddi: "54",
    pais: "Argentina +54",
    continente: "América do Sul",
  },
  {
    ddi: "374",
    pais: "Armênia +374",
    continente: "Ásia",
  },
  {
    ddi: "297",
    pais: "Aruba +297",
    continente: "América Central",
  },
  {
    ddi: "247",
    pais: "Ascensão +247",
    continente: "África",
  },
  {
    ddi: "61",
    pais: "Austrália +61",
    continente: "Oceania",
  },
  {
    ddi: "43",
    pais: "Áustria +43",
    continente: "Europa",
  },
  {
    ddi: "994",
    pais: "Azerbaijão +994",
    continente: "Ásia",
  },
  {
    ddi: "1",
    pais: "Bahamas +1",
    continente: "América Central",
  },
  {
    ddi: "880",
    pais: "Bangladesh +880",
    continente: "Ásia",
  },
  {
    ddi: "1",
    pais: "Barbados +1",
    continente: "América Central",
  },
  {
    ddi: "973",
    pais: "Bahrein +973",
    continente: "Ásia",
  },
  {
    ddi: "32",
    pais: "Bélgica +32",
    continente: "Europa",
  },
  {
    ddi: "501",
    pais: "Belize +501",
    continente: "América Central",
  },
  {
    ddi: "229",
    pais: "Benim +229",
    continente: "África",
  },
  {
    ddi: "1",
    pais: "Bermudas +1",
    continente: "América Central",
  },
  {
    ddi: "375",
    pais: "Bielorrússia +375",
    continente: "Europa",
  },
  {
    ddi: "591",
    pais: "Bolívia +591",
    continente: "América do Sul",
  },
  {
    ddi: "387",
    pais: "Bósnia e Herzegovina +387",
    continente: "Europa",
  },
  {
    ddi: "267",
    pais: "Botswana +267",
    continente: "África",
  },
  {
    ddi: "55",
    pais: "Brasil +55",
    continente: "América do Sul",
  },
  {
    ddi: "673",
    pais: "Brunei +673",
    continente: "Ásia",
  },
  {
    ddi: "359",
    pais: "Bulgária +359",
    continente: "Europa",
  },
  {
    ddi: "226",
    pais: "Burkina Faso +226",
    continente: "África",
  },
  {
    ddi: "257",
    pais: "Burundi +257",
    continente: "África",
  },
  {
    ddi: "975",
    pais: "Butão +975",
    continente: "Ásia",
  },
  {
    ddi: "238",
    pais: "Cabo Verde +238",
    continente: "África",
  },
  {
    ddi: "237",
    pais: "Camarões +237",
    continente: "África",
  },
  {
    ddi: "855",
    pais: "Camboja +855",
    continente: "Ásia",
  },
  {
    ddi: "1",
    pais: "Canadá +1",
    continente: "América do Norte",
  },
  {
    ddi: "7",
    pais: "Cazaquistão +7",
    continente: "Ásia",
  },
  {
    ddi: "237",
    pais: "Chade +237",
    continente: "África",
  },
  {
    ddi: "56",
    pais: "Chile +56",
    continente: "América do Sul",
  },
  {
    ddi: "86",
    pais: "República Popular da China +86",
    continente: "Ásia",
  },
  {
    ddi: "357",
    pais: "Chipre +357",
    continente: "Europa",
  },
  {
    ddi: "57",
    pais: "Colômbia +57",
    continente: "América do Sul",
  },
  {
    ddi: "269",
    pais: "Comores +269",
    continente: "África",
  },
  {
    ddi: "242",
    pais: "Congo-Brazzaville +242",
    continente: "África",
  },
  {
    ddi: "243",
    pais: "Congo-Kinshasa +243",
    continente: "África",
  },
  {
    ddi: "850",
    pais: "Coreia do Norte +850",
    continente: "Ásia",
  },
  {
    ddi: "82",
    pais: "Coreia do Sul +82",
    continente: "Ásia",
  },
  {
    ddi: "225",
    pais: "Costa do Marfim +225",
    continente: "África",
  },
  {
    ddi: "506",
    pais: "Costa Rica +506",
    continente: "América Central",
  },
  {
    ddi: "385",
    pais: "Croácia +385",
    continente: "Europa",
  },
  {
    ddi: "53",
    pais: "Cuba +53",
    continente: "América Central",
  },
  {
    ddi: "45",
    pais: "Dinamarca +45",
    continente: "Europa",
  },
  {
    ddi: "253",
    pais: "Djibuti +253",
    continente: "África",
  },
  {
    ddi: "1",
    pais: "Dominica +1",
    continente: "América Central",
  },
  {
    ddi: "20",
    pais: "Egipto +20",
    continente: "África/Ásia",
  },
  {
    ddi: "503",
    pais: "El Salvador +503",
    continente: "América Central",
  },
  {
    ddi: "971",
    pais: "Emirados Árabes Unidos +971",
    continente: "Ásia",
  },
  {
    ddi: "593",
    pais: "Equador +593",
    continente: "América do Sul",
  },
  {
    ddi: "291",
    pais: "Eritreia +291",
    continente: "África",
  },
  {
    ddi: "421",
    pais: "Eslováquia +421",
    continente: "Europa",
  },
  {
    ddi: "386",
    pais: "Eslovénia +386",
    continente: "Europa",
  },
  {
    ddi: "34",
    pais: "Espanha +34",
    continente: "Europa",
  },
  {
    ddi: "1",
    pais: "Estados Unidos +1",
    continente: "América do Norte",
  },
  {
    ddi: "372",
    pais: "Estónia +372",
    continente: "Europa",
  },
  {
    ddi: "251",
    pais: "Etiópia +251",
    continente: "África",
  },
  {
    ddi: "679",
    pais: "Fiji +679",
    continente: "Oceania",
  },
  {
    ddi: "63",
    pais: "Filipinas +63",
    continente: "Ásia",
  },
  {
    ddi: "358",
    pais: "Finlândia +358",
    continente: "Europa",
  },
  {
    ddi: "33",
    pais: "França +33",
    continente: "Europa",
  },
  {
    ddi: "241",
    pais: "Gabão +241",
    continente: "África",
  },
  {
    ddi: "220",
    pais: "Gâmbia +220",
    continente: "África",
  },
  {
    ddi: "233",
    pais: "Gana +233",
    continente: "África",
  },
  {
    ddi: "995",
    pais: "Geórgia +995",
    continente: "Ásia",
  },
  {
    ddi: "350",
    pais: "Gibraltar +350",
    continente: "Europa",
  },
  {
    ddi: "1",
    pais: "Granada +1",
    continente: "América Central",
  },
  {
    ddi: "30",
    pais: "Grécia +30",
    continente: "Europa",
  },
  {
    ddi: "299",
    pais: "Groenlândia +299",
    continente: "América do Norte",
  },
  {
    ddi: "590",
    pais: "Guadalupe +590",
    continente: "América Central",
  },
  {
    ddi: "671",
    pais: "Guam +671",
    continente: "Oceania",
  },
  {
    ddi: "502",
    pais: "Guatemala +502",
    continente: "América Central",
  },
  {
    ddi: "592",
    pais: "Guiana +592",
    continente: "América do Sul",
  },
  {
    ddi: "594",
    pais: "Guiana Francesa +594",
    continente: "América do Sul",
  },
  {
    ddi: "224",
    pais: "Guiné +224",
    continente: "África",
  },
  {
    ddi: "245",
    pais: "Guiné-Bissau +245",
    continente: "África",
  },
  {
    ddi: "240",
    pais: "Guiné Equatorial +240",
    continente: "África",
  },
  {
    ddi: "509",
    pais: "Haiti +509",
    continente: "América Central",
  },
  {
    ddi: "504",
    pais: "Honduras +504",
    continente: "América Central",
  },
  {
    ddi: "852",
    pais: "Hong Kong +852",
    continente: "Ásia",
  },
  {
    ddi: "36",
    pais: "Hungria +36",
    continente: "Europa",
  },
  {
    ddi: "967",
    pais: "Iêmen +967",
    continente: "Ásia",
  },
  {
    ddi: "1",
    pais: "Ilhas Cayman +1",
    continente: "América Central",
  },
  {
    ddi: "672",
    pais: "Ilha Christmas +672",
    continente: "Oceania",
  },
  {
    ddi: "672",
    pais: "Ilhas Cocos +672",
    continente: "Oceania",
  },
  {
    ddi: "682",
    pais: "Ilhas Cook +682",
    continente: "Oceania",
  },
  {
    ddi: "298",
    pais: "Ilhas Féroe +298",
    continente: "Europa",
  },
  {
    ddi: "672",
    pais: "Ilha Heard e Ilhas McDonald +672",
    continente: "Oceania",
  },
  {
    ddi: "960",
    pais: "Maldivas +960",
    continente: "Ásia",
  },
  {
    ddi: "500",
    pais: "Ilhas Malvinas +500",
    continente: "América do Sul",
  },
  {
    ddi: "1",
    pais: "Ilhas Marianas do Norte +1",
    continente: "Oceania",
  },
  {
    ddi: "692",
    pais: "Ilhas Marshall +692",
    continente: "Oceania",
  },
  {
    ddi: "672",
    pais: "Ilha Norfolk +672",
    continente: "Oceania",
  },
  {
    ddi: "677",
    pais: "Ilhas Salomão +677",
    continente: "Oceania",
  },
  {
    ddi: "1",
    pais: "Ilhas Virgens Americanas +1",
    continente: "América Central",
  },
  {
    ddi: "1",
    pais: "Ilhas Virgens Britânicas +1",
    continente: "América Central",
  },
  {
    ddi: "91",
    pais: "Índia +91",
    continente: "Ásia",
  },
  {
    ddi: "62",
    pais: "Indonésia +62",
    continente: "Ásia/Oceania",
  },
  {
    ddi: "98",
    pais: "Irã +98",
    continente: "Ásia",
  },
  {
    ddi: "964",
    pais: "Iraque +964",
    continente: "Ásia",
  },
  {
    ddi: "353",
    pais: "Irlanda +353",
    continente: "Europa",
  },
  {
    ddi: "354",
    pais: "Islândia +354",
    continente: "Europa",
  },
  {
    ddi: "972",
    pais: "Israel +972",
    continente: "Ásia",
  },
  {
    ddi: "39",
    pais: "Itália +39",
    continente: "Europa",
  },
  {
    ddi: "1",
    pais: "Jamaica +1",
    continente: "América Central",
  },
  {
    ddi: "81",
    pais: "Japão +81",
    continente: "Ásia",
  },
  {
    ddi: "962",
    pais: "Jordânia +962",
    continente: "Ásia",
  },
  {
    ddi: "686",
    pais: "Kiribati +686",
    continente: "Oceania",
  },
  {
    ddi: "383",
    pais: "Kosovo +383",
    continente: "Europa",
  },
  {
    ddi: "965",
    pais: "Kuwait +965",
    continente: "Ásia",
  },
  {
    ddi: "856",
    pais: "Laos +856",
    continente: "Ásia",
  },
  {
    ddi: "266",
    pais: "Lesoto +266",
    continente: "África",
  },
  {
    ddi: "371",
    pais: "Letônia +371",
    continente: "Europa",
  },
  {
    ddi: "961",
    pais: "Líbano +961",
    continente: "Ásia",
  },
  {
    ddi: "231",
    pais: "Libéria +231",
    continente: "África",
  },
  {
    ddi: "218",
    pais: "Líbia +218",
    continente: "África",
  },
  {
    ddi: "423",
    pais: "Liechtenstein +423",
    continente: "Europa",
  },
  {
    ddi: "370",
    pais: "Lituânia +370",
    continente: "Europa",
  },
  {
    ddi: "352",
    pais: "Luxemburgo +352",
    continente: "Europa",
  },
  {
    ddi: "853",
    pais: "Macau +853",
    continente: "Ásia",
  },
  {
    ddi: "389",
    pais: "República da Macedônia +389",
    continente: "Europa",
  },
  {
    ddi: "261",
    pais: "Madagascar +261",
    continente: "África",
  },
  {
    ddi: "60",
    pais: "Malásia +60",
    continente: "Ásia",
  },
  {
    ddi: "265",
    pais: "Malawi +265",
    continente: "África",
  },
  {
    ddi: "223",
    pais: "Mali +223",
    continente: "África",
  },
];

export const dddList = [
  {
    ddd: "61",
    location: "Distrito federal +61",
  },
  {
    ddd: "62",
    location: "Goiás +62",
  },
  {
    ddd: "64",
    location: "Goiás +64",
  },
  {
    ddd: "65",
    location: "Mato Grosso +65",
  },
  {
    ddd: "66",
    location: "Mato Grosso +66",
  },
  {
    ddd: "67",
    location: "Mato Grosso do Sul +65",
  },
  {
    ddd: "82",
    location: "Alagoas +82",
  },
  {
    ddd: "71",
    location: "Bahia +71",
  },
  {
    ddd: "73",
    location: "Bahia +73",
  },
  {
    ddd: "74",
    location: "Bahia +74",
  },
  {
    ddd: "75",
    location: "Bahia +75",
  },
  {
    ddd: "77",
    location: "Bahia +77",
  },
  {
    ddd: "85",
    location: "Ceará +85",
  },
  {
    ddd: "88",
    location: "Ceará +88",
  },
  {
    ddd: "98",
    location: "Maranhão +98",
  },
  {
    ddd: "99",
    location: "Maranhão +90",
  },
  {
    ddd: "83",
    location: "Paraíba +83",
  },
  {
    ddd: "81",
    location: "Pernambuco +81",
  },
  {
    ddd: "87",
    location: "Pernambuco +87",
  },
  {
    ddd: "86",
    location: "Piauí +86",
  },
  {
    ddd: "89",
    location: "Piauí +89",
  },
  {
    ddd: "84",
    location: "Rio Grande do Norte +84",
  },
  {
    ddd: "79",
    location: "Sergipe +79",
  },
  {
    ddd: "68",
    location: "Acre +68",
  },
  {
    ddd: "96",
    location: "Amapá +96",
  },
  {
    ddd: "92",
    location: "Amazonas +92",
  },
  {
    ddd: "97",
    location: "Amazonas +97",
  },
  {
    ddd: "91",
    location: "Pará +91",
  },
  {
    ddd: "93",
    location: "Pará +93",
  },
  {
    ddd: "94",
    location: "Pará +94",
  },
  {
    ddd: "69",
    location: "Rondônia +69",
  },
  {
    ddd: "95",
    location: "Roraima +95",
  },
  {
    ddd: "63",
    location: "Tocantins +63",
  },
  {
    ddd: "27",
    location: "Espírito Santo +27",
  },
  {
    ddd: "28",
    location: "Espírito Santo +28",
  },
  {
    ddd: "31",
    location: "Minas Gerais +31",
  },
  {
    ddd: "32",
    location: "Minas Gerais +32",
  },
  {
    ddd: "33",
    location: "Minas Gerais +33",
  },
  {
    ddd: "34",
    location: "Minas Gerais +34",
  },
  {
    ddd: "35",
    location: "Minas Gerais +35",
  },
  {
    ddd: "37",
    location: "Minas Gerais +37",
  },
  {
    ddd: "38",
    location: "Minas Gerais +38",
  },
  {
    ddd: "21",
    location: "Rio de Janeiro +21",
  },
  {
    ddd: "22",
    location: "Rio de Janeiro +22",
  },
  {
    ddd: "24",
    location: "Rio de Janeiro +24",
  },
  {
    ddd: "11",
    location: "São Paulo +11",
  },
  {
    ddd: "13",
    location: "São Paulo +13",
  },
  {
    ddd: "14",
    location: "São Paulo +14",
  },
  {
    ddd: "15",
    location: "São Paulo +15",
  },
  {
    ddd: "16",
    location: "São Paulo +16",
  },
  {
    ddd: "17",
    location: "São Paulo +17",
  },
  {
    ddd: "18",
    location: "São Paulo +18",
  },
  {
    ddd: "19",
    location: "São Paulo +19",
  },
  {
    ddd: "41",
    location: "Paraná +41",
  },
  {
    ddd: "42",
    location: "Paraná +42",
  },
  {
    ddd: "43",
    location: "Paraná +43",
  },
  {
    ddd: "44",
    location: "Paraná +44",
  },
  {
    ddd: "45",
    location: "Paraná +45",
  },
  {
    ddd: "46",
    location: "Paraná +46",
  },
  {
    ddd: "51",
    location: "Rio Grande do Sul +51",
  },
  {
    ddd: "53",
    location: "Rio Grande do Sul +53",
  },
  {
    ddd: "54",
    location: "Rio Grande do Sul +54",
  },
  {
    ddd: "55",
    location: "Rio Grande do Sul +55",
  },
  {
    ddd: "47",
    location: "Santa Catarina +47",
  },
  {
    ddd: "48",
    location: "Santa Catarina +48",
  },
  {
    ddd: "49",
    location: "Santa Catarina +49",
  },
];

export const selectTypeCountrie = [
  {
    name: "DDI",
    value: "ddi",
  },
  {
    name: "Coluna",
    value: "column",
  },
];
export const selectTypeState = [
  {
    name: "DDD",
    value: "ddd",
  },
  {
    name: "Coluna",
    value: "column",
  },
];

export const selectJustColumn = [
  {
    name: "Coluna",
    value: "column",
  },
];
