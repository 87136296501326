<template>
  <div class="step">
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    steppers: { type: Array, default: null, required: true },
    currentStepper: { type: Number, default: 1 },
  },
  computed: {
    title() {
      const msg =
        this.steppers.find((el) => el.id == this.currentStepper).label || "Conexão";
      return msg;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.step {
  padding: 0.8rem;
  span {
    color: #0273c3;
    font-weight: 600;
    font-size: 1.25rem;
  }
}
</style>
